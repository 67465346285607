import { useState } from 'react';
import { Button, Table } from 'react-bootstrap';
import pdfNeuroQuestions from '../../assets/pdf/Neurodevelopment-Referral-Questions.pdf';
import AccordionComponent from '../base/accordionComponent.js';

//use to generate questions pdf, then add to assets to save downloads
//import { pdfNeuroQuestions } from '../../utils/sendRequest.js';

// const pdfDownload = async () => {
//   try {
//     const resPdf = await pdfNeuroQuestions();

//     const file = new Blob([resPdf], { type: 'application/pdf' });
//     //IE fix
//     // window navigator - detect browser
//     //msSaveOrOpenBlob ie method to save a file in a browser
//     if (window.navigator && window.navigator.msSaveOrOpenBlob) {
//       window.navigator.msSaveOrOpenBlob(file);
//       return;
//     }

//     //Build a URL from the file
//     const fileURL = URL.createObjectURL(file);
//     const link = document.createElement('a');
//     link.href = fileURL;
//     link.download = `Neuro-Referral .pdf`;
//     link.click();

//     setTimeout(() => {
//       URL.revokeObjectURL(link);
//     }, 2000);
//   } catch (error) {}
// };

export default function OpeningPage() {
  const [collapseOpen, setCollapseOpen] = useState(false);

  return (
    <>
      <h1 className="mt-2 mb-2">Neurodevelopmental Pathway: Referral Form</h1>
      <div className="mb-2">
        {/* <p className="text-danger">
          IMPORTANT: THIS FORM IS CURRENTLY ON-LINE FOR TESTING ONLY AND{' '}
          <strong>NO REFERRALS MADE USING THIS FORM WILL BE ACCEPTED</strong>{' '}
          UNTIL TESTING IS COMPLETE. IF YOU ARE TESTING THE FORM PLEASE
          <strong> DO NOT RECORD REAL CHILDRENS IDENTIFYING DETAILS</strong>.
          USE ONLY FICTIONAL CHILD DETAILS FOR TESTING.{' '}
        </p> */}
        <h4> Criteria for form submission</h4>
        <p className="mb-0">
          In order to successfully complete and submit an ASD web referral for
          triage by the NDP service the following minimum criteria must be met
          in the form. The referral <strong>will</strong> be rejected if the
          child or young person does not meet all the criteria.
        </p>
        <ul className="mb-3">
          <li className="criteria-li-padding">
            For ASD, referred child is aged between 2 years and 6 months old and
            15 years and 364 days old. For ADHD, child is aged between 5 years
            and 0 months old and 15 years and 364 days old. <strong>AND</strong>
          </li>
          <li className="criteria-li-padding">
            They <strong>do not</strong> have a <strong>severe</strong> learning
            disability. <strong>AND</strong>
          </li>
          <li className="criteria-li-padding">
            The referral is made by a health, education or social care
            professional (referrals by parents are not accepted).{' '}
            <strong>AND</strong>
          </li>
          <li className="criteria-li-padding">
            Evidence that an Early Help or graduated approach to support the
            child or young person has been implemented over a minimum of 6
            months or two terms for school aged children. <strong>AND</strong>
          </li>
          <li className="criteria-li-padding">
            All other mandatory questions are answered. <strong>AND</strong>
          </li>
          <ul>
            <li className="criteria-li-padding">
              They are registered with a Birmingham GP. <strong>OR</strong>
            </li>
            <li className="criteria-li-padding">
              They are not registered with any GP but are resident in the
              Birmingham Local Authority area.
            </li>
          </ul>

          <li>
            <AccordionComponent
              titleBodyText={'Who should be referred?'}
              accordionBodyText={
                <p>
                  We accept referrals for children experiencing significant
                  difficulties or differences, impacting on the child/young
                  person’s life across the areas of their development associated
                  with ASD: Communication and reciprocal social interaction and
                  social imagination; and restrictive/repetitive patterns of
                  behaviour. All referrals to NDP ASD now require documented
                  evidence of intervention or support. We require evidence that
                  an Early Help or graduated approach to support the young
                  person has been implemented over time. This must be in place
                  and evidenced over a minimum of 6 months or two terms for all
                  school aged children.
                </p>
              }
            />
          </li>
          <li>
            <AccordionComponent
              titleBodyText={'Has the child been supported?'}
              accordionBodyText={
                <p>
                  In a “graduated approach” there would be an expectation, as an
                  example, that a child’s school or family have worked with
                  practitioners to implement strategies, monitored their
                  progress, and evaluated the outcome. A referral to a service
                  or one-off appointment with a practitioner would not be
                  considered a graduated approach to support. If you are not
                  able to clearly identify that the child has received support
                  in relation to their presenting needs prior to a referral we
                  will not be able to accept the referral. It is important that
                  evidence provided{' '}
                  <a
                    href="https://www.bhamcommunity.nhs.uk/ndp-asd-referrals"
                    target="_blank"
                    rel="noreferrer"
                  >
                    is in line with the guidance here
                  </a>
                </p>
              }
            />
          </li>
          <li>
            <AccordionComponent
              titleBodyText={'Reviewing your referral'}
              accordionBodyText={
                <p>
                  For appropriate referrals where there is supporting evidence,
                  we will subsequently request a range of detailed information
                  in addition to this evidence in the form of questionnaires to
                  the families and the setting. There will be a wait time from
                  referral being submitted to questionnaires being sent to
                  families and setting. Once all this information has been
                  gathered, a full review of all this information will take
                  place to confirm if the NDP is the most appropriate pathway
                  for a child or young person.
                </p>
              }
            />
          </li>
          <li>
            <AccordionComponent
              titleBodyText={'Helpful information'}
              accordionBodyText={
                <p>
                  It is not unusual for children to have different presentations
                  at home and at school and it is important that we know about
                  these differences. If a child is not experiencing as many
                  needs in the setting/school as at home, we would like school
                  and parents to meet and monitor the needs over two terms. We
                  ask that the referral is supported with information that helps
                  us understand these differences and support implemented.
                </p>
              }
            />
          </li>
          <li>
            <AccordionComponent
              titleBodyText={'Re-referrals'}
              accordionBodyText={
                <p>
                  We are unable to accept re-referrals until at least 18 months
                  has lapsed since the outcome of the previous assessment. We
                  also require evidence of significant changes in the child’s
                  presentation or new information since the previous assessment
                  was carried out before a re-referral is made.
                </p>
              }
            />
          </li>
        </ul>

        <a
          href="https://www.bhamcommunity.nhs.uk/patients-public/children-and-young-people/services-parent-portal/neurodevelopmental-pathway/"
          target="_blank"
          rel="noreferrer"
        >
          For further information please visit the BCHC Neurodevelopmental
          Pathway web page
        </a>

        <p className="mt-2 mb-2">
          <strong>IMPORTANT INFORMATION FOR REFERRERS:</strong> The Birmingham
          Community Healthcare NHS Trust's Neurodevelopmental Pathway (Neuro)
          service uses a secure on-line referral form to ensure that referrals
          for assessment of autism spectrum disorder and attention deficit
          hyperactivity disorder contain all necessary information to enable
          clinical triage and assessment. The majority of questions in the form
          are mandatory and the form cannot be submitted unless all these are
          answered. Please review the information below before referring to
          ensure you have all necessary information ready to complete the form
        </p>
        <p className="mt-2 mb-1">
          If you would like to export this list for the parent to complete some
          information before referring please click the export question list
          button below.
        </p>

        <div className="mb-2">
          <Button
            variant="secondary"
            type="button"
            onClick={() => window.open(pdfNeuroQuestions, '_blank')}
            //onClick={() => pdfDownload()}
          >
            Export questions list
          </Button>
        </div>
        <p>
          If you encounter any technical issue which is preventing you from
          accessing or completing the form please contact the Neurodevelopmental
          Pathway’s Central Booking Service on 0121 683 2320 (option 4) and one
          of the team will assist.
        </p>
        <p className="mt-2 mb-1">
          Please note that this form may take up to 45 minutes to complete. You
          cannot save during the process, so please allow time to fully complete
          and submit.
        </p>
      </div>
      <Table responsive="lg">
        <thead>
          <tr>
            <th>Field Name</th>
            <th>Mandatory</th>
            <th>Further information</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>Child's First Name</td>
            <td>Y</td>
            <td></td>
          </tr>
          <tr>
            <td>Child's Last Name</td>
            <td>Y</td>
            <td></td>
          </tr>
          <tr>
            <td>Child's DOB</td>
            <td>Y</td>
            <td>
              For ASD, referred child is aged between 2 years and 6 months old
              and 15 years and 364 days old. For ADHD, child is aged between 5
              years and 0 months old and 15 years and 364 days old
            </td>
          </tr>
          <tr>
            <td>Do you wish to refer the child to</td>
            <td>Y</td>
            <td>
              For ASD, referred child is aged between 2 years and 6 months old
              and 15 years and 364 days old. For ADHD, child is aged between 5
              years and 0 months old and 15 years and 364 days old
            </td>
          </tr>
          <tr>
            <td>
              Please detail evidence that an Early Help or graduated approach to
              support the child or young person has been implemented over a
              minimum of 6 months or two terms for school aged children. If
              there is no Early Help or graduated approach to support the child
              or young person in place or the evidence detailed is inadequate
              the referral will be rejected.
            </td>
            <td>Y</td>
            <td></td>
          </tr>
          <tr>
            <td>Child's Ethnicity</td>
            <td>Y</td>
            <td></td>
          </tr>

          <tr>
            <td>Is the child residing with</td>
            <td>Y</td>
            <td></td>
          </tr>
          <tr>
            <td>Social Workers Name</td>
            <td>N</td>
            <td rowSpan={4}>
              Mandatory questions if child residing with Local Authority
            </td>
          </tr>
          <tr>
            <td>Social Worker telephone contact no.</td>
            <td>N</td>
          </tr>
          <tr>
            <td>Social Worker email address</td>
            <td>N</td>
          </tr>
          <tr>
            <td>How long as the child been at their current placement</td>
            <td>N</td>
          </tr>
          <tr>
            <td>Child's Address</td>
            <td>Y</td>
            <td>Address can be selected through a postcode search</td>
          </tr>
          <tr>
            <td>Is child registered with a GP</td>
            <td>Y</td>
            <td rowSpan={2}>
              If the child is registered with a GP this{' '}
              <strong>must be a Birmingham Practice.</strong> If the child is
              registered with a practice outside of Birmingham the referral can
              not be submitted
            </td>
          </tr>
          <tr>
            <td>Child GP</td>
            <td>Y</td>
          </tr>
          <tr>
            <td>Does the child have a severe learning disability?</td>
            <td>Y</td>
            <td>
              If the child has a <strong>severe learning disability</strong>{' '}
              they can not be assessed by the Neuro service and should be
              referred to an alternative provider
            </td>
          </tr>
          <tr>
            <td>Referrer Consent</td>
            <td>Y</td>
            <td></td>
          </tr>
          <tr>
            <td>Person with Parental Responsibility</td>
            <td>Y</td>
            <td></td>
          </tr>
          <tr>
            <td>Referrer Consent Date</td>
            <td>Y</td>
            <td></td>
          </tr>
          <tr>
            <td>Referrer Name</td>
            <td>Y</td>
            <td></td>
          </tr>

          {collapseOpen ? (
            <>
              <tr>
                <td>Referrer Title/Position</td>
                <td>Y</td>
                <td></td>
              </tr>
              <tr>
                <td>Referrer Email</td>
                <td>Y</td>
                <td>
                  This enables future communications with referrer with respect
                  to status of referral
                </td>
              </tr>
              <tr>
                <td>Referrer Telephone</td>
                <td>Y</td>
                <td></td>
              </tr>
              <tr>
                <td>Referrer Mobile</td>
                <td>N</td>
                <td>Mandatory if no referrer telephone provided</td>
              </tr>
              <tr>
                <td>Referrer Address</td>
                <td>Y</td>
                <td>Address can be selected through a postcode search</td>
              </tr>
              <tr>
                <td>
                  Does the person with parental responsibility give permission
                  for the Neurodevelopmental Team to contact the following
                  services for further information
                </td>
                <td>Y</td>
                <td>
                  <strong>
                    In order for assessment to proceed it is essential that
                    permission is given to contact school or education provider
                  </strong>{' '}
                  as they will need to complete questionnaires for assessment
                </td>
              </tr>
              <tr>
                <td>NHS No. (if known)</td>
                <td>N</td>
                <td></td>
              </tr>
              <tr>
                <td>Parent Name</td>
                <td>Y</td>
                <td></td>
              </tr>
              <tr>
                <td>Parent / Carer’s email address</td>
                <td>N</td>
                <td></td>
              </tr>
              <tr>
                <td>Parent / Carer’s phone number</td>
                <td>N</td>
                <td></td>
              </tr>
              <tr>
                <td>Parent/Current Carer Address if different to child</td>
                <td>Y</td>
                <td>Address can be selected through a postcode search</td>
              </tr>
              <tr>
                <td>Interpreter required</td>
                <td>Y</td>
                <td></td>
              </tr>
              <tr>
                <td>Interpreter language</td>
                <td>N</td>
                <td></td>
              </tr>
              <tr>
                <td>Is child in school/nursery setting</td>
                <td>Y</td>
                <td></td>
              </tr>
              <tr>
                <td>Is child in home education</td>
                <td>Y</td>
                <td></td>
              </tr>
              <tr>
                <td>Child School/Nursery if in school/nursery setting</td>
                <td>Y</td>
                <td></td>
              </tr>
              <tr>
                <td>School/Nursery Name Other</td>
                <td>N</td>
                <td>Mandatory if 'Other' selected for child school/nursery</td>
              </tr>
              <tr>
                <td>School/Nursery Address</td>
                <td>Y</td>
                <td></td>
              </tr>
              <tr>
                <td>School/Nursery Contact Person</td>
                <td>N</td>
                <td rowSpan={4}>
                  In order to facilitate completion of assessment please provide
                  contact details so school or education provider can be
                  contacted to complete questionnaires necessary for assessment
                </td>
              </tr>
              <tr>
                <td>School/Nursery Contact Person Telephone</td>
                <td>N</td>
              </tr>
              <tr>
                <td>School/Nursery Email</td>
                <td>N</td>
              </tr>
              <tr>
                <td>School/Nursery Telephone</td>
                <td>N</td>
              </tr>
              <tr>
                <td>
                  The child's behaviour endangers their safety, in a way that
                  cannot be managed
                </td>
                <td>Y</td>
                <td></td>
              </tr>
              <tr>
                <td>
                  The child's behaviour endangers the safety of others in a way
                  that cannot be managed
                </td>
                <td>Y</td>
                <td></td>
              </tr>
              <tr>
                <td>
                  The child's school / nursery has already or is considering
                  excluding or permanently excluding them
                </td>
                <td>Y</td>
                <td></td>
              </tr>
              <tr>
                <td>
                  Is the child in the care of the Local Authority (a Child in
                  Care)
                </td>
                <td>Y</td>
                <td></td>
              </tr>
              <tr>
                <td>The child is being considered for adoption</td>
                <td>Y</td>
                <td></td>
              </tr>
              <tr>
                <td>Is there a child protection plan in place</td>
                <td>Y</td>
                <td></td>
              </tr>
              <tr>
                <td>Is there a child in need plan for the child</td>
                <td>Y</td>
                <td></td>
              </tr>
              <tr>
                <td>Is the child referred to Safeguarding Children Services</td>
                <td>Y</td>
                <td></td>
              </tr>
              <tr>
                <td>Education Plans in Place</td>
                <td>Y</td>
                <td></td>
              </tr>
              <tr>
                <td>Reason for referral</td>
                <td>Y</td>
                <td></td>
              </tr>
              <tr>
                <td>
                  Compared to other children of their age the child uses eye
                  contact
                </td>
                <td>Y</td>
                <td></td>
              </tr>
              <tr>
                <td>
                  Compared to other children of their age the child uses gesture
                  to share their intentions
                </td>
                <td>Y</td>
                <td></td>
              </tr>
              <tr>
                <td>
                  Compared to other children of their age the child shares their
                  emotions (excitement, pain, fear, happiness)
                </td>
                <td>Y</td>
                <td></td>
              </tr>
              <tr>
                <td>
                  The child has a specific area of interest that is more intense
                  or detailed than other children of their age
                </td>
                <td>Y</td>
                <td></td>
              </tr>
              <tr>
                <td>
                  The child has a specific area of interest that is unusual
                  compared to other children of their age
                </td>
                <td>Y</td>
                <td></td>
              </tr>
              <tr>
                <td>
                  The child has specific routines they need to keep to
                  throughout the day
                </td>
                <td>Y</td>
                <td></td>
              </tr>
              <tr>
                <td>
                  The child has routines or rituals that cause them a level of
                  distress if they are not completed
                </td>
                <td>Y</td>
                <td></td>
              </tr>
              <tr>
                <td>The child understands and responds to spoken language</td>
                <td>Y</td>
                <td></td>
              </tr>
              <tr>
                <td>The child uses spoken language</td>
                <td>Y</td>
                <td></td>
              </tr>
              <tr>
                <td>The child follows and joins in conversations</td>
                <td>Y</td>
                <td></td>
              </tr>
              <tr>
                <td>
                  The child understands and uses facial expressions, tone of
                  voice and body language
                </td>
                <td>Y</td>
                <td></td>
              </tr>
              <tr>
                <td>
                  The child can sustain their attention to a self chosen task
                  for
                </td>
                <td>Y</td>
                <td></td>
              </tr>
              <tr>
                <td>
                  The child can sustain their attention to an adult led task for
                </td>
                <td>Y</td>
                <td></td>
              </tr>
              <tr>
                <td>
                  The child can adjust their activity levels to the social
                  context
                </td>
                <td>Y</td>
                <td></td>
              </tr>
              <tr>
                <td>The child responds to loud sounds or noisy environments</td>
                <td>Y</td>
                <td></td>
              </tr>
              <tr>
                <td>The child responds to tastes and textures</td>
                <td>Y</td>
                <td></td>
              </tr>
              <tr>
                <td>Child's learning</td>
                <td>Y</td>
                <td></td>
              </tr>
              <tr>
                <td>The child has settled into school / nursery</td>
                <td>Y</td>
                <td></td>
              </tr>
              <tr>
                <td>Has the child had a sight test</td>
                <td>Y</td>
                <td></td>
              </tr>
              <tr>
                <td>Do you have concerns about their sight</td>
                <td>Y</td>
                <td></td>
              </tr>
              <tr>
                <td>Has the child passed a hearing test</td>
                <td>Y</td>
                <td></td>
              </tr>
              <tr>
                <td>Do you have concerns about their hearing</td>
                <td>Y</td>
                <td></td>
              </tr>
              <tr>
                <td>
                  Do you have concerns about the child's fine motor skills
                  (writing, doing up buttons, zips)
                </td>
                <td>Y</td>
                <td></td>
              </tr>
              <tr>
                <td>
                  Do you have concerns about the child's gross motor skills
                  (walking running jumping)
                </td>
                <td>Y</td>
                <td></td>
              </tr>
              <tr>
                <td>Child's behaviour</td>
                <td>Y</td>
                <td></td>
              </tr>
              <tr>
                <td>
                  Does the child show any stereotypical mannerisms / stimming
                </td>
                <td>Y</td>
                <td></td>
              </tr>
              <tr>
                <td>
                  Has the child ever lost any motor skills? (e.g. walking skills
                  worsened significantly or lost ability to walk after achieving
                  this)
                </td>
                <td>Y</td>
                <td></td>
              </tr>
              <tr>
                <td>Does the child have moderate to severe learning delay</td>
                <td>Y</td>
                <td></td>
              </tr>
              <tr>
                <td>Is there a family history of Autism/ADHD/learning delay</td>
                <td>Y</td>
                <td></td>
              </tr>
              <tr>
                <td>
                  Does the child/young person already have a diagnosis of ADHD
                  and/or ASD
                </td>
                <td>Y</td>
                <td></td>
              </tr>
              <tr>
                <td>Do you have documentation to support diagnosis</td>
                <td>Y</td>
                <td>Option to upload if yes</td>
              </tr>
              <tr>
                <td>
                  Compared to other children/young people of their age, do they
                  have a short attention span
                </td>
                <td>Y</td>
                <td rowSpan={12}>Mandatory questions if ADHD referral</td>
              </tr>
              <tr>
                <td>
                  Compared to other children/young people of their age are they
                  easily distracted
                </td>
                <td>Y</td>
              </tr>
              <tr>
                <td>
                  Compared to other children/young people of their age do they
                  have poor organisation
                </td>
                <td>Y</td>
              </tr>
              <tr>
                <td>
                  Compared to other children/young people of their age do they
                  struggle to complete given tasks
                </td>
                <td>Y</td>
              </tr>
              <tr>
                <td>
                  Compared to other children/young people of their age, does
                  this child display more fidgeting
                </td>
                <td>Y</td>
              </tr>
              <tr>
                <td>
                  Compared to other children/young people of their age, does
                  this child display more chatting
                </td>
                <td>Y</td>
              </tr>
              <tr>
                <td>
                  Compared to other children/young people of their age, does
                  this child display more squirming
                </td>
                <td>Y</td>
              </tr>
              <tr>
                <td>
                  Compared to other children/young people of their age, does
                  this child display not keeping still
                </td>
                <td>Y</td>
              </tr>
              <tr>
                <td>
                  Compared to other children/young people of their age, does
                  this child display more acting without thinking
                </td>
                <td>Y</td>
              </tr>

              <tr>
                <td>
                  Compared to other children/young people of their age, does
                  this child display more interrupting conversations
                </td>
                <td>Y</td>
              </tr>

              <tr>
                <td>
                  Compared to other children/young people of their age, does
                  this child display more difficulties waiting for their turn
                </td>
                <td>Y</td>
              </tr>

              <tr>
                <td>
                  Compared to other children/young people of their age, does
                  this child display more risky behaviour without consideration
                  of consequence
                </td>
                <td>Y</td>
              </tr>

              <tr>
                <td>Any additional medical concerns</td>
                <td>Y</td>
                <td></td>
              </tr>
              <tr>
                <td>Additional Information</td>
                <td>N</td>
                <td></td>
              </tr>
            </>
          ) : null}
        </tbody>
      </Table>
      <div className="d-flex justify-content-center">
        {collapseOpen ? (
          <p className="text-muted">End of questions list</p>
        ) : (
          <Button
            className="mb-2"
            onClick={() => setCollapseOpen(!collapseOpen)}
          >
            Show complete questions list
          </Button>
        )}
      </div>
    </>
  );
}
